import { MakeObjectTuple } from "../utility.types";

export interface KeyCloakAttributesExtracted extends VerificationState {
  cittaResidenza: string;
  titolo: string;
  dataNascita: string;
  iscrizioneOrdine: string;
  provinciaNascita: string;
  telefono: string;
  codiceFiscale: string;
  provinciaIscrizioneOrdine: string;
  specializzazione: string;
}

export type KeyCloakAttributes = MakeObjectTuple<KeyCloakAttributesExtracted>;

export interface VerificationState {
  userState: string;
  validationMessage: string;
}

export enum USER_ROLES {
  admin="admin",  writer="writer", user="user", sponsor="sponsor"
}

export interface User {
  id: string;
  created: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  attributes: KeyCloakAttributes;
  conditionsTerminiServizio: boolean;
  conditionsDatiFunzionamento: boolean;
  conditionsDatiElaborazioneStatistica: boolean;
  conditionsDatiFinalitaComunicazione: boolean;
  conditionsDatiFinalitaMessaggi: boolean;
  conditionsDatiFinalitaMiglioramento: boolean;
  roles: USER_ROLES[];
  imported:boolean
}

